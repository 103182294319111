import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import SideNavbar from "./side-navbar";
import blogContent from "./content/all-blogs";
import topicContent from "./content/all-topics";
import Layout from "../../templates/layout";

if (process.env.WEBPACK) require("./styles/topic-page.scss");

const TopicPage = (props) => {
  const { topic, def, html, seeAlso, blogs, metaDescription } =
    props.pageContext.topic;

  return (
    <Layout>
      <div className="g-topic-page g-topic-page--layout">
        <Helmet
          title={`${topic} - VdoCipher Glossary`}
          meta={[
            { name: "description", content: `${metaDescription}` },
            { property: "og:title", content: `${topic}` },
            { property: "og:type", content: "website" },
            { name: "twitter:card", content: "summary" },
            { name: "twitter:site", content: "@vdocipher" },
          ]}
        />
        <SideNavbar />
        <div className={`g-topic-page__main`}>
          <div className="container">
            <h2 className="g-topic-page__title--font g-topic-page__title--padding h2--text-center">
              {topic}
            </h2>
            <h4 className="g-topic-page__def--font g-topic-page__def--padding">
              {def}
            </h4>
            <div
              className="g-topic-page__details"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <div className="g-topic-page__see-also-div">
              {seeAlso.length > 0 && (
                <h4>
                  <span>See Also:</span>
                  {seeAlso
                    .map(
                      (
                        topicId // convert the ids to objects
                      ) =>
                        topicContent.find((topic) => topic.topicId === topicId)
                    )
                    .filter(Boolean)
                    .map((topic, i, validTopics) => (
                      <Link
                        key={i}
                        className="g-topic-page__see-also"
                        to={`/glossary/${topic.topicId}`}
                      >
                        {topic.topic}
                        {i === validTopics.length - 1 ? " " : ","}
                      </Link>
                    ))}
                </h4>
              )}
            </div>
            <div className="g-topic-page__blogs">
              {blogs.length > 0 && (
                <h4>
                  Blogs:
                  <ul>
                    {blogs
                      .filter((wpId) => blogContent[wpId])
                      .map((wpId, i) => (
                        <li key={i}>
                          <a
                            href={blogContent[wpId].link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {blogContent[wpId].title}
                          </a>
                        </li>
                      ))}
                  </ul>
                </h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TopicPage;
