const RAWBLOGS = [
  {
    wpId: 4072,
    link: "https://www.vdocipher.com/blog/demo-fps/",
    title: "Demo FPS",
  },
  {
    wpId: 4052,
    link: "https://www.vdocipher.com/blog/vod-website-netflix-tutorial/",
    title: "VOD website launcher for media, build own Netflix: Tutorial",
  },
  {
    wpId: 4050,
    link: "https://www.vdocipher.com/blog/vod-website-elearning-tutorial/",
    title: "VOD website launcher for e-learning businesses: Video Tutorial",
  },
  {
    wpId: 3954,
    link: "https://www.vdocipher.com/blog/microsoft-playready-drm-infrastructure-implementation-for-premium-content/",
    title:
      "Microsoft Playready DRM: Infrastructure Implementation for Premium Content",
  },
  {
    wpId: 3493,
    link: "https://www.vdocipher.com/blog/fairplay-drm-ios-safari-html5/",
    title: "FairPlay DRM configuration for iOS and Safari",
  },
  {
    wpId: 3449,
    link: "https://www.vdocipher.com/blog/demo-flash-secure-player/",
    title: "demo flash secure player",
  },
  {
    wpId: 2638,
    link: "https://www.vdocipher.com/blog/vdocipher-overview-video/",
    title: "VdoCipher Overview Video",
  },
  {
    wpId: 1987,
    link: "https://www.vdocipher.com/blog/upload-embed-videos-vdocipher-tutorial/",
    title: "How to upload & embed videos with VdoCipher ?- Tutorial",
  },
  {
    wpId: 1841,
    link: "https://www.vdocipher.com/blog/password-protect-videos/",
    title: "Password Protect Videos: Register, Encrypt, High Secure",
  },
  {
    wpId: 1808,
    link: "https://www.vdocipher.com/blog/attractive-wp-themes-elearning-courses/",
    title: "Attractive WP Themes for E-Learning Courses",
  },
  {
    wpId: 1740,
    link: "https://www.vdocipher.com/blog/washington-post-video-content-marketing/",
    title: "Washington Post -- Video in Content Marketing",
  },
  {
    wpId: 1435,
    link: "https://www.vdocipher.com/blog/rtmp-streaming-support-cdn/",
    title: "CDN Support for RTMP Stream Declining",
  },
  {
    wpId: 1391,
    link: "https://www.vdocipher.com/blog/tech-update-netflix-updates-codecs-use-efficient-encoding/",
    title: "Tech Update: Netflix Updates Codecs Use for Efficient Encoding",
  },
  {
    wpId: 1309,
    link: "https://www.vdocipher.com/blog/chrome-flash-html5-update-vdocipher/",
    title: "Chrome Flash & HTML 5 update: VdoCipher Player Case Study",
  },
  {
    wpId: 1277,
    link: "https://www.vdocipher.com/blog/popular-video-downloader-statistics-elearningindustry/",
    title: "Popular Video Downloader Statistics for Elearningindustry",
  },
  {
    wpId: 1025,
    link: "https://www.vdocipher.com/blog/history-of-streaming-media/",
    title: "A Business & Entertainment History of Streaming Media",
  },
  {
    wpId: 939,
    link: "https://www.vdocipher.com/blog/secure-video-streaming-elearning-pdf/",
    title: "Secure Video Streaming In E-learning -- eBook",
  },
  {
    wpId: 572,
    link: "https://www.vdocipher.com/blog/alternative-to-kaltura-secure-smooth-customizable/",
    title: "Alternative to Kaltura: Secure, Smooth, Customizable",
  },
  {
    wpId: 522,
    link: "https://www.vdocipher.com/blog/alternative-to-playready-silverlight-drm-vdocipher-watermark-browser-compatible/",
    title: "Playready DRM with Vdocipher",
  },
  {
    wpId: 509,
    link: "https://www.vdocipher.com/blog/alternative-to-dacast-secure-smooth-customizable/",
    title: "Alternative to Dacast: Secure, Smooth, Customizable",
  },
  {
    wpId: 506,
    link: "https://www.vdocipher.com/blog/alternative-to-sproutvideo-vdocipher-secure-affordable/",
    title: "Alternative to SproutVideo: VdoCipher- Secure, Customizable",
  },
  {
    wpId: 503,
    link: "https://www.vdocipher.com/blog/alternative-to-streamingvideoprovider-vdocipher-secure-affordable/",
    title:
      "Alternative to StreamingVideoProvider: Secure, Smooth, Customizable",
  },
  {
    wpId: 500,
    link: "https://www.vdocipher.com/blog/alternative-to-vimeo-secure-customizable/",
    title: "Alternative to Vimeo: Secure for Business",
  },
  {
    wpId: 480,
    link: "https://www.vdocipher.com/blog/online-drm-player-for-video/",
    title: "DRM Player : Secure Video Player",
  },
  {
    wpId: 1122,
    link: "https://www.vdocipher.com/blog/what-is-transcoding/",
    title: "What is Transcoding?",
  },
  {
    wpId: 496,
    link: "https://www.vdocipher.com/blog/alternative-to-wistia-vdocipher-secure-affordable/",
    title: "Alternative to Wistia: VdoCipher- Secure & Affordable",
  },
  {
    wpId: 4083,
    link: "https://www.vdocipher.com/blog/2019/03/netflix-business-model-video-selling-platform/",
    title: "Netflix Business Model : World's Largest Video Selling Platform",
  },
  {
    wpId: 3800,
    link: "https://www.vdocipher.com/blog/2018/11/encrypted-media-extensions-eme/",
    title: "An Introduction to Encrypted Media Extensions (EME)",
  },
  {
    wpId: 3908,
    link: "https://www.vdocipher.com/blog/2018/11/video-quality-bitrate-pixels/",
    title:
      "Understanding Video Quality -- Pixels (p), Bitrate (kbps), Aspect Ratios",
  },
  {
    wpId: 3803,
    link: "https://www.vdocipher.com/blog/2018/10/video-player-themes/",
    title: "Video Player Themes -- Add Custom Skins and Change Player Controls",
  },
  {
    wpId: 3690,
    link: "https://www.vdocipher.com/blog/2018/08/top-online-video-courses-world/",
    title: "Top Online Video Courses Across World: Business, Arts & Computers",
  },
  {
    wpId: 3668,
    link: "https://www.vdocipher.com/blog/2018/08/online-ias-coaching-video-classes/",
    title:
      "How Indian UPSC or IAS test prep coaching institutes are using online videos ?",
  },
  {
    wpId: 3584,
    link: "https://www.vdocipher.com/blog/2018/08/ott-india-revolution-vod/",
    title: "How OTT is Revolutionizing TV Experience in India",
  },
  {
    wpId: 3635,
    link: "https://www.vdocipher.com/blog/2018/07/ultraviolet-rights-management-system-works/",
    title: "How UltraViolet Rights Management System Works",
  },
  {
    wpId: 3603,
    link: "https://www.vdocipher.com/blog/2018/07/asia-vod-platforms/",
    title: "Top 20 Online VOD Platforms in Asia [UPDATED 2018]",
  },
  {
    wpId: 3567,
    link: "https://www.vdocipher.com/blog/2018/07/virtual-classroom/",
    title: "Virtual Classroom: Learning beyond four walls",
  },
  {
    wpId: 3557,
    link: "https://www.vdocipher.com/blog/2018/07/shemaroo-vod-video-streaming-app/",
    title: "Shemaroo to launch VOD platform via streaming app",
  },
  {
    wpId: 3512,
    link: "https://www.vdocipher.com/blog/2018/07/aes-128-encryption-video-drm-secure/",
    title: "How AES-128 Encryption for video compares to DRM systems",
  },
  {
    wpId: 3369,
    link: "https://www.vdocipher.com/blog/2018/06/wordpress-lms-plugin-sensei-website/",
    title:
      "WordPress LMS Plugin Sensei & LMS theme for Course Website Creation: Guide",
  },
  {
    wpId: 3189,
    link: "https://www.vdocipher.com/blog/2018/05/video-player-color-change/",
    title: "Customize Video Player -- Change Player Color in WordPress",
  },
  {
    wpId: 3083,
    link: "https://www.vdocipher.com/blog/2018/05/video-api-v3/",
    title: "Video APIs to Automate your Video Workflow -- API Version v3",
  },
  {
    wpId: 2953,
    link: "https://www.vdocipher.com/blog/2018/03/vdocipher-featured-global-media-germany-switzerland-nigeria/",
    title: "VdoCipher featured in Global Media : Germany, Switzerland, Nigeria",
  },
  {
    wpId: 2888,
    link: "https://www.vdocipher.com/blog/2018/03/advanced-real-time-video-analytics/",
    title: "Advanced Video Analytics -- Understand Your Viewers",
  },
  {
    wpId: 2863,
    link: "https://www.vdocipher.com/blog/2018/02/3-ingredients-building-killer-movie-recommendation-engine/",
    title: "3 Ingredients for building a killer Movie Recommendation Engine",
  },
  {
    wpId: 2708,
    link: "https://www.vdocipher.com/blog/2018/02/guide-elearning-vod-playbook/",
    title:
      "Your Guide to Creating an eLearning Business -- VOD Platform Playbook",
  },
  {
    wpId: 2675,
    link: "https://www.vdocipher.com/blog/2018/01/7-key-ingredients-to-building-a-massively-successful-ott-platform/",
    title: "7 Key Ingredients to building a massively successful OTT Platform",
  },
  {
    wpId: 2590,
    link: "https://www.vdocipher.com/blog/2017/12/vdocipher-teachable-thinkific-learnworlds-sell-online-courses/",
    title:
      "Sell Online Courses: VdoCipher Integrates with Ready to Use Websites",
  },
  {
    wpId: 2493,
    link: "https://www.vdocipher.com/blog/2017/11/video-metadata/",
    title: "Video Metadata -- Optimize to Increase Your Viewers",
  },
  {
    wpId: 2329,
    link: "https://www.vdocipher.com/blog/2017/11/svod-vs-avod-video-on-demand/",
    title:
      "SVOD (Subscriber Video on Demand) vs AVOD (Advertising Video on Demand)",
  },
  {
    wpId: 2469,
    link: "https://www.vdocipher.com/blog/2017/11/video-cms-vdocipher/",
    title: "Video CMS -- Host, Organize and Share Videos",
  },
  {
    wpId: 2279,
    link: "https://www.vdocipher.com/blog/2017/10/transactional-video-on-demand-tvod-platform/",
    title: "TVOD -- Build a Transactional Video on Demand Platform",
  },
  {
    wpId: 2209,
    link: "https://www.vdocipher.com/blog/2017/09/tvod-vs-svod-video-on-demand-platform-vod/",
    title: "VOD Platform Builder and TVOD vs SVOD Comparison",
  },
  {
    wpId: 2111,
    link: "https://www.vdocipher.com/blog/2017/08/hls-streaming-hls-encryption-secure-hls-drm/",
    title: "HLS Streaming, HLS Encryption & Setting High Secure DRM",
  },
  {
    wpId: 2079,
    link: "https://www.vdocipher.com/blog/2017/07/hollywood-premium-video-on-demand/",
    title: "Hollywood's Premium Video on Demand Model",
  },
  {
    wpId: 2054,
    link: "https://www.vdocipher.com/blog/2017/07/subscription-video-on-demand-platform-svod/",
    title: "SVOD -- Build a Subscription Video on Demand Platform",
  },
  {
    wpId: 2033,
    link: "https://www.vdocipher.com/blog/2017/06/video-on-demand-platform-vod-vdocipher/",
    title: "Video on Demand Platform for Media & E-learning Businesses",
  },
  {
    wpId: 1922,
    link: "https://www.vdocipher.com/blog/2017/06/netflix-revolution-part-1-history/",
    title: "The Netflix revolution -- Part 1: History of Netflix",
  },
  {
    wpId: 1859,
    link: "https://www.vdocipher.com/blog/2017/04/premier-league-online-video-piracy/",
    title: "Premier League is Battling Online Video Piracy",
  },
  {
    wpId: 1874,
    link: "https://www.vdocipher.com/blog/2017/04/import-videos-box/",
    title: "Import videos from Box.com",
  },
  {
    wpId: 1812,
    link: "https://www.vdocipher.com/blog/2017/03/free-lms-plugins-wordpress/",
    title: "The Definitive Guide to Free LMS Plugins for WordPress",
  },
  {
    wpId: 1787,
    link: "https://www.vdocipher.com/blog/2017/03/payment-gateway-sell-online-course/",
    title: "Payment Gateway Options to Sell Online Course",
  },
  {
    wpId: 1693,
    link: "https://www.vdocipher.com/blog/2017/02/e-learning-video-production-selecting-camera/",
    title: "E-Learning Video Production -- Selecting a Camera",
  },
  {
    wpId: 1681,
    link: "https://www.vdocipher.com/blog/2017/01/flipped-classroom-video-secure-hosting/",
    title: "Secure Hosting for Flipped Classroom Videos",
  },
  {
    wpId: 1644,
    link: "https://www.vdocipher.com/blog/2017/01/set-ip-geo-restriction-videos-via-api-vdocipher-drm/",
    title: "Set IP Geo restriction on videos via API/Dashboard: VdoCipher DRM",
  },
  {
    wpId: 1609,
    link: "https://www.vdocipher.com/blog/2017/01/how-to-build-an-e-learning-website-5-point-evaluation/",
    title: "How to Build an E-Learning Website -- 5-Point evaluation",
  },
  {
    wpId: 1580,
    link: "https://www.vdocipher.com/blog/2017/01/streaming-video-hosting-market-reach/",
    title: "Streaming Video Hosting to Improve Market Reach",
  },
  {
    wpId: 1495,
    link: "https://www.vdocipher.com/blog/2017/01/import-video-vimeo-wistia-dropbox/",
    title: "Import Videos From Vimeo Pro, Google Drive and Dropbox",
  },
  {
    wpId: 1478,
    link: "https://www.vdocipher.com/blog/2016/12/import-videos-s3-bucket/",
    title: 'Import videos from S3 bucket with "Import from AWS S3"',
  },
  {
    wpId: 1464,
    link: "https://www.vdocipher.com/blog/2016/12/using-tin-can-api-in-video/",
    title: "Using Tin Can API in video: Complete Documentation",
  },
  {
    wpId: 1292,
    link: "https://www.vdocipher.com/blog/2016/12/online-video-course-guide/",
    title: "Complete Guide to Creating Your Online Video Course",
  },
  {
    wpId: 1297,
    link: "https://www.vdocipher.com/blog/2016/12/gold-standard-video-drm-streaming-media-global-readers-choice-award/",
    title: "Gold Standard in Video DRM: Streaming Media Readers' Choice Award",
  },
  {
    wpId: 1170,
    link: "https://www.vdocipher.com/blog/2016/12/make-online-video-course-engaging/",
    title: "Make Your Online Video Course More Engaging with these tips",
  },
  {
    wpId: 1250,
    link: "https://www.vdocipher.com/blog/2016/12/secure-video-streaming-nodejs-embed/",
    title: "Nodejs sample code to generate OTP for secure video streaming",
  },
  {
    wpId: 1040,
    link: "https://www.vdocipher.com/blog/2016/12/video-hosting-for-business/",
    title: "Video Hosting for Business: Player, Analytics, Security",
  },
  {
    wpId: 924,
    link: "https://www.vdocipher.com/blog/2016/11/wordpress-plugins-for-elearning/",
    title: "Top 10 WordPress Plugins For E-Learning",
  },
  {
    wpId: 864,
    link: "https://www.vdocipher.com/blog/2016/11/rtmp-encrypted-rtmpe-streaming-technology/",
    title: "RTMPe Streaming: How does Secure RTMP Streaming work?",
  },
  {
    wpId: 812,
    link: "https://www.vdocipher.com/blog/2016/10/wp-emember-wordpress-membership-plugin/",
    title:
      "WP eMember as WordPress Membership Plugin: User Management Tutorial",
  },
  {
    wpId: 762,
    link: "https://www.vdocipher.com/blog/2016/10/wordpress-course-plugin-to-sell-online-courses-sensei-tutorial/",
    title: "WordPress course plugin to make course website – Sensei tutorial",
  },
  {
    wpId: 737,
    link: "https://www.vdocipher.com/blog/2016/10/autoplay-loop-video-custom-video-player-controls-vdocipher/",
    title: "Auto play, Loop Video, Time Calculation, Custom Video Player",
  },
  {
    wpId: 705,
    link: "https://www.vdocipher.com/blog/2016/09/vdocipher-workflow-demo-secure-video-streaming/",
    title: "VdoCipher package Workflow and demo -- Secure video streaming",
  },
  {
    wpId: 695,
    link: "https://www.vdocipher.com/blog/2016/09/secure-video-streaming-api-vdocipher-for-developers/",
    title: "Secure Video Streaming API: VdoCipher for Developers",
  },
  {
    wpId: 686,
    link: "https://www.vdocipher.com/blog/2016/09/statistics-video-download-tools-secure-video-streaming/",
    title: "How many use easy video download piracy tools ?",
  },
  {
    wpId: 680,
    link: "https://www.vdocipher.com/blog/2016/08/vdocipher-fit-tech-stack/",
    title: "Where does VdoCipher DRM video fit in your tech stack.",
  },
  {
    wpId: 547,
    link: "https://www.vdocipher.com/blog/2016/08/why-not-to-host-videos-on-vimeo-youtube/",
    title: "Business Video Alternative to Vimeo and YouTube",
  },
  {
    wpId: 637,
    link: "https://www.vdocipher.com/blog/2016/08/encrypted-video-streaming-vdocipher-technology-details/",
    title: "Encrypted Video Streaming: VdoCipher & Others",
  },
  {
    wpId: 633,
    link: "https://www.vdocipher.com/blog/2016/08/top-3-offline-encoder-for-video-audio-conversion-on-mac-windows/",
    title: "3 Offline Encoder to Compress Video: MAC & Windows",
  },
  {
    wpId: 629,
    link: "https://www.vdocipher.com/blog/2016/08/list-of-popular-video-downloaders-vdocipher-stops-all/",
    title: "10 popular video downloaders: VdoCipher stops all",
  },
  {
    wpId: 624,
    link: "https://www.vdocipher.com/blog/2016/08/why-not-to-self-host-videos-challenges-with-in-house-streaming/",
    title: "Why Not to Host Your Own Videos? Challenges in Self-hosting videos",
  },
  {
    wpId: 592,
    link: "https://www.vdocipher.com/blog/2016/07/setting-the-desired-bitrate-for-video-playback/",
    title: "Setting desired bitrate for video playback for multiple devices",
  },
  {
    wpId: 579,
    link: "https://www.vdocipher.com/blog/2016/07/aws-cdn-infrastructure-details-vdocipher-video-streaming/",
    title: "AWS + CDN Infrastructure: Hosting, Streaming Details",
  },
  {
    wpId: 569,
    link: "https://www.vdocipher.com/blog/2016/07/video-hosting-online-courses-vdocipher-e-learning/",
    title: "VdoCipher for E-learning: Video Hosting for Online Courses",
  },
  {
    wpId: 531,
    link: "https://www.vdocipher.com/blog/2016/07/video-management-details-through-vdocipher-dashboard/",
    title: "Video Management details on VdoCipher dashboard",
  },
  {
    wpId: 477,
    link: "https://www.vdocipher.com/blog/2016/06/faster-uploads-to-vdocipher-aws-acceleration-aspera/",
    title: "Faster video upload to VdoCipher: AWS acceleration + Aspera",
  },
  {
    wpId: 453,
    link: "https://www.vdocipher.com/blog/2016/03/asp-net-web-forms-embed-vdocipher-video/",
    title: "ASP.net C# web forms embed vdocipher video",
  },
  {
    wpId: 450,
    link: "https://www.vdocipher.com/blog/2016/03/php-sample-video-upload-from-browser-to-vdocipher/",
    title: "PHP sample video upload from browser to VdoCipher",
  },
  {
    wpId: 440,
    link: "https://www.vdocipher.com/blog/2016/02/drupal-module-to-embed-vdocipher-videos/",
    title: "Drupal module to embed VdoCipher videos",
  },
  {
    wpId: 438,
    link: "https://www.vdocipher.com/blog/2016/02/c-sample-video-upload-from-browser-to-vdocipher/",
    title: "C# sample video upload from browser to VdoCipher",
  },
  {
    wpId: 434,
    link: "https://www.vdocipher.com/blog/2016/02/example-code-for-streaming-protected-video-in-asp-net/",
    title: "Code to embed vdocipher video in ASP.NET MVC with C#",
  },
  {
    wpId: 427,
    link: "https://www.vdocipher.com/blog/2016/02/load-video-files-from-public-ftp-server/",
    title: "Load video files from public ftp server",
  },
  {
    wpId: 424,
    link: "https://www.vdocipher.com/blog/2016/02/account-usage-via-api/",
    title: "Bandwidth account usage API for video streaming",
  },
  {
    wpId: 392,
    link: "https://www.vdocipher.com/blog/2016/02/video-upload-using-api/",
    title: "Video upload from website or server to VdoCipher API",
  },
  {
    wpId: 374,
    link: "https://www.vdocipher.com/blog/2015/11/affordable-video-streaming-vdocipher-new-price-plans/",
    title: "Affordable Video Streaming: Simplified Price Plans",
  },
  {
    wpId: 361,
    link: "https://www.vdocipher.com/blog/2015/10/custom-variables-watermark-on-wordpress-videos/",
    title: "Custom variables as watermark on WordPress videos",
  },
  {
    wpId: 359,
    link: "https://www.vdocipher.com/blog/2015/10/change-video-poster-image-in-your-secure-video/",
    title: "Change video poster image in your secure video",
  },
  {
    wpId: 354,
    link: "https://www.vdocipher.com/blog/2015/09/videos-in-rails-with-vdocipher-gem/",
    title: "Add videos in rails app with VdoCipher ruby gem",
  },
  {
    wpId: 350,
    link: "https://www.vdocipher.com/blog/2015/09/quickest-way-to-start-your-video-selling-site/",
    title: "Quickest Way to Start Your Video Selling Site",
  },
  {
    wpId: 475,
    link: "https://www.vdocipher.com/blog/2015/06/demo-multi-bitrate-optimized-encoding/",
    title: "Demo: Encrypted + Watermark + Multi-Bitrate Streaming",
  },
  {
    wpId: 329,
    link: "https://www.vdocipher.com/blog/2015/05/multi-bitrate-streaming-player-stream-video-at-any-speed-any-quality/",
    title:
      "Multi bitrate streaming player -- Stream video at any speed, any quality",
  },
  {
    wpId: 321,
    link: "https://www.vdocipher.com/blog/2015/05/asynchronous-video-embed/",
    title: "Asynchronous video embed",
  },
  {
    wpId: 301,
    link: "https://www.vdocipher.com/blog/2015/04/adding-videos-from-url/",
    title: "Adding videos from URL",
  },
  {
    wpId: 290,
    link: "https://www.vdocipher.com/blog/2015/04/how-got-video-leak-could-have-been-prevented-with-vdocipher/",
    title: "How GOT video leak could have been prevented with VdoCipher",
  },
  {
    wpId: 1352,
    link: "https://www.vdocipher.com/blog/2015/03/watermarking-for-enforcing-drm/",
    title: "Watermarking to enforce DRM for Protected Streaming",
  },
  {
    wpId: 274,
    link: "https://www.vdocipher.com/blog/2015/02/joomla-plugin-secure-videos-vdocipher/",
    title: "Joomla video plugin to secure videos with VdoCipher",
  },
  {
    wpId: 256,
    link: "https://www.vdocipher.com/blog/2015/01/asp-net-implementation-vdocipher-api/",
    title: "ASP.NET implementation of VdoCipher API",
  },
  {
    wpId: 219,
    link: "https://www.vdocipher.com/blog/2015/01/video-upload-api-with-tags/",
    title: "Video upload API with tag based listing",
  },
  {
    wpId: 233,
    link: "https://www.vdocipher.com/blog/2015/01/tag-your-videos-and-search-using-api/",
    title: "Tag your videos and search using API",
  },
  {
    wpId: 346,
    link: "https://www.vdocipher.com/blog/2015/01/the-many-limits-of-html5-why-flash-is-not-going-anytime-soon/",
    title: "The many limits of HTML5 & Why Flash is not going anytime soon?",
  },
  {
    wpId: 224,
    link: "https://www.vdocipher.com/blog/2014/12/php-implementation-vdocipher-api/",
    title: "PHP implementation of VdoCipher API",
  },
  {
    wpId: 201,
    link: "https://www.vdocipher.com/blog/2014/12/asp-implementation-vdocipher-api/",
    title: "ASP implementation of VdoCipher API",
  },
  {
    wpId: 212,
    link: "https://www.vdocipher.com/blog/2014/12/protect-videos-on-wordpress/",
    title: "WordPress video plugin : Add Secure Videos to your WP site",
  },
  {
    wpId: 205,
    link: "https://www.vdocipher.com/blog/2014/12/add-text-to-videos-with-watermark/",
    title: "Add text to video with dynamic watermarking",
  },
  {
    wpId: 191,
    link: "https://www.vdocipher.com/blog/2014/12/secure-video-streaming-vdocipher-technology/",
    title: "Secure video streaming and hosting: Advances in technology",
  },
  {
    wpId: 177,
    link: "https://www.vdocipher.com/blog/2014/12/cheap-video-hosting-vdocipher/",
    title: "Affordable video hosting with VdoCipher",
  },
  {
    wpId: 97,
    link: "https://www.vdocipher.com/blog/2014/09/sell-videos-online-prevent-download/",
    title: "Looking to sell videos online : Protection from piracy",
  },
  {
    wpId: 60,
    link: "https://www.vdocipher.com/blog/2014/09/money-from-e-learning-videos/",
    title: "How to make money from education videos ?",
  },
  {
    wpId: 45,
    link: "https://www.vdocipher.com/blog/2014/08/lighting-for-video-recording/",
    title: "Setting up lighting for video recording",
  },
  {
    wpId: 269,
    link: "https://www.vdocipher.com/blog/2014/08/variables-on-wordpress-videos/",
    title: "Watermark user/time specific dynamic variables on WordPress videos",
  },
  {
    wpId: 3037,
    link: "https://www.vdocipher.com/blog/widevine-drm-hollywood-video/",
    title: "Widevine DRM & CDM for Security of Premium Content",
  },
];

const ALLBLOGS = {};
RAWBLOGS.forEach((blog) => {
  ALLBLOGS[blog.wpId] = {
    link: blog.link,
    title: blog.title,
  };
});

export default ALLBLOGS;
